import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/insight/importer/insightImporterSelectors';
import InsightService from 'src/modules/insight/insightService';
import fields from 'src/modules/insight/importer/insightImporterFields';
import { i18n } from 'src/i18n';

const insightImporterActions = importerActions(
  'INSIGHT_IMPORTER',
  selectors,
  InsightService.import,
  fields,
  i18n('entities.insight.importer.fileName'),
);

export default insightImporterActions;