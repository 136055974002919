import listActions from 'src/modules/product/list/productListActions';
import ProductService from 'src/modules/product/productService';
import Errors from 'src/modules/shared/error/errors';
import { i18n } from 'src/i18n';
import Message from 'src/view/shared/message';

const prefix = 'PRODUCT_GENERATESLIDES';

const productGenerateSlidesActions = {
  GENERATESLIDES_STARTED: `${prefix}_GENERATESLIDES_STARTED`,
  GENERATESLIDES_SUCCESS: `${prefix}_GENERATESLIDES_SUCCESS`,
  GENERATESLIDES_ERROR: `${prefix}_GENERATESLIDES_ERROR`,


  doGenerateSlides: (id,setSlideDownloadUrl) => async (dispatch) => {
    try {
      dispatch({
        type: productGenerateSlidesActions.GENERATESLIDES_STARTED,
      });

      const slideFile = await ProductService.generateSlides([id]);
      
      dispatch({
        type: productGenerateSlidesActions.GENERATESLIDES_SUCCESS,
      });

      Message.success(
        i18n('entities.product.generateslides.success'),
      );

      setSlideDownloadUrl(slideFile);
    
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: productGenerateSlidesActions.GENERATESLIDES_ERROR,
      });
    }
  },
};

export default productGenerateSlidesActions;
