import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import companyEnumerators from 'src/modules/company/companyEnumerators';

export default [
  {
    name: 'name',
    label: i18n('entities.company.fields.name'),
    schema: schemas.string(
      i18n('entities.company.fields.name'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'description',
    label: i18n('entities.company.fields.description'),
    schema: schemas.string(
      i18n('entities.company.fields.description'),
      {},
    ),
  },
  {
    name: 'employeeCount',
    label: i18n('entities.company.fields.employeeCount'),
    schema: schemas.enumerator(
      i18n('entities.company.fields.employeeCount'),
      {
        "required": true,
        "options": companyEnumerators.employeeCount
      },
    ),
  },
  {
    name: 'industry',
    label: i18n('entities.company.fields.industry'),
    schema: schemas.relationToOne(
      i18n('entities.company.fields.industry'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'country',
    label: i18n('entities.company.fields.country'),
    schema: schemas.relationToOne(
      i18n('entities.company.fields.country'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'website',
    label: i18n('entities.company.fields.website'),
    schema: schemas.string(
      i18n('entities.company.fields.website'),
      {},
    ),
  },
  {
    name: 'founded',
    label: i18n('entities.company.fields.founded'),
    schema: schemas.integer(
      i18n('entities.company.fields.founded'),
      {},
    ),
  },
];