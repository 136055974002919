import DefinitionService from 'src/modules/definition/definitionService';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';

const prefix = 'BOOKMARK_VIEW';

const definitionViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: definitionViewActions.FIND_STARTED,
      });

      const record = await DefinitionService.find(id);

      dispatch({
        type: definitionViewActions.FIND_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: definitionViewActions.FIND_ERROR,
      });

      getHistory().push('/definition');
    }
  },
};

export default definitionViewActions;
