import listActions from 'src/modules/product/list/productListActions';
import ProductService from 'src/modules/product/productService';
import Errors from 'src/modules/shared/error/errors';
import { i18n } from 'src/i18n';
import Message from 'src/view/shared/message';

const prefix = 'PRODUCT_RECALCULATE';

const productRecalculateActions = {
  RECALCULATE_STARTED: `${prefix}_RECALCULATE_STARTED`,
  RECALCULATE_SUCCESS: `${prefix}_RECALCULATE_SUCCESS`,
  RECALCULATE_ERROR: `${prefix}_RECALCULATE_ERROR`,


  doRecalculate: (id,forceUpdate,setForceUpdate) => async (dispatch) => {
    try {
      dispatch({
        type: productRecalculateActions.RECALCULATE_STARTED,
      });

      await ProductService.recalculate([id]);

      dispatch({
        type: productRecalculateActions.RECALCULATE_SUCCESS,
      });

      Message.success(
        i18n('entities.product.recalculate.success'),
      );
      
      // Update charts and analytics following recalculation
      setForceUpdate(forceUpdate+1);

    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: productRecalculateActions.RECALCULATE_ERROR,
      });
    }
  },
};

export default productRecalculateActions;
