import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.insight.fields.id'),
  },
  {
    name: 'insight',
    label: i18n('entities.insight.fields.insight'),
  },
  {
    name: 'source',
    label: i18n('entities.insight.fields.source'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'collectedBy',
    label: i18n('entities.insight.fields.collectedBy'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'page',
    label: i18n('entities.insight.fields.page'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.insight.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.insight.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
