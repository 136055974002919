import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/feed/importer/feedImporterSelectors';
import FeedService from 'src/modules/feed/feedService';
import fields from 'src/modules/feed/importer/feedImporterFields';
import { i18n } from 'src/i18n';

const feedImporterActions = importerActions(
  'CUSTOMER_IMPORTER',
  selectors,
  FeedService.import,
  fields,
  i18n('entities.feed.importer.fileName'),
);

export default feedImporterActions;