import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/research/importer/researchImporterSelectors';
import ResearchService from 'src/modules/research/researchService';
import fields from 'src/modules/research/importer/researchImporterFields';
import { i18n } from 'src/i18n';

const researchImporterActions = importerActions(
  'RESEARCH_IMPORTER',
  selectors,
  ResearchService.import,
  fields,
  i18n('entities.research.importer.fileName'),
);

export default researchImporterActions;