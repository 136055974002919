import Permissions from 'src/security/permissions';
import { i18n } from 'src/i18n';
import React from 'react';
import DashboardIcon from '@material-ui/icons/Dashboard';
import BookmarkIcon from '@material-ui/icons/InsertLink';
import DefinitionIcon from '@material-ui/icons/SortByAlpha';
import BoardIcon from '@material-ui/icons/List';
import DeckIcon from '@material-ui/icons/Slideshow';
import AdminIcon from '@material-ui/icons/Settings';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import CohortIcon from '@material-ui/icons/Group';
import ReferenceIcon from '@material-ui/icons/LibraryBooks';
import TopicIcon from '@material-ui/icons/LibraryBooks';
import AppsIcon from '@material-ui/icons/Apps';
import TimeLineIcon from '@material-ui/icons/Timeline';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ContactsIcon from '@material-ui/icons/Contacts';
import EventsIcon from '@material-ui/icons/Event';
import LabelIcon from '@material-ui/icons/Label';
import DataIcon from '@material-ui/icons/FilterNone';
import BulbIcon from '@material-ui/icons/WbIncandescent';
import FeedIcon from '@material-ui/icons/RssFeed';
import HelpIcon from '@material-ui/icons/Help';

const permissions = Permissions.values;

export default [
  {
    path: '/',
    exact: true,
    icon: <DashboardIcon />,
    label: i18n('dashboard.menu'),
    permissionRequired: null,
  },

  {
    path: '/research',
    permissionRequired: permissions.researchRead,
    icon: <MenuBookIcon />,
    label: i18n('entities.research.menu'),
  },

  {
    path: '/insight',
    permissionRequired: permissions.insightRead,
    icon: <BulbIcon />,
    label: i18n('entities.insight.menu'),
  },

  {
    path: '/bookmark',
    permissionRequired: permissions.bookmarkRead,
    icon: <BookmarkIcon />,
    label: i18n('entities.bookmark.menu'),
  },

  {
    path: '/feedboard',
    permissionRequired: permissions.feedRead,
    icon: <FeedIcon />,
    label: i18n('entities.feed.menu'),
  },

  {
    path: '/definition',
    permissionRequired: permissions.definitionRead,
    icon: <DefinitionIcon />,
    label: i18n('entities.definition.menu'),
  },

  {
    path: '/reference',
    permissionRequired: permissions.bookmarkRead,
    icon: <ReferenceIcon />,
    label: i18n('entities.reference.menu'),
  },

  {
    path: '/topic/byname/Home',
    permissionRequired: permissions.topicRead,
    icon: <TopicIcon />,
    label: i18n('entities.topic.menu'),
  },

  {
    path: '/board',
    permissionRequired: permissions.boardRead,
    icon: <BoardIcon />,
    label: i18n('entities.board.menu'),
  },

  {
    path: '/market-segment',
    permissionRequired: permissions.marketSegmentRead,
    icon: <GroupWorkIcon />,
    label: i18n('entities.marketSegment.menu'),
  },

  {
    path: '/cohort',
    permissionRequired: permissions.cohortRead,
    icon: <CohortIcon />,
    label: i18n('entities.cohort.menu'),
  },

  {
    path: '/product',
    permissionRequired: permissions.productRead,
    icon: <AppsIcon />,
    label: i18n('entities.product.menu'),
  },

  {
    path: '/model',
    permissionRequired: permissions.modelRead,
    icon: <TimeLineIcon />,
    label: i18n('entities.model.menu'),
  },
  {
    path: '/deck',
    permissionRequired: permissions.deckRead,
    icon: <DeckIcon/>,
    label: i18n('entities.deck.menu'),
  },

  {
    path: '/tag',
    permissionRequired: permissions.tagRead,
    icon: <LabelIcon />,
    label: i18n('entities.tag.menu'),
  },

  {
    path: '/customer',
    permissionRequired: permissions.customerRead,
    icon: <AttachMoneyIcon />,
    label: i18n('entities.customer.menu'),
  },
  {
    path: '/contact',
    permissionRequired: permissions.contactRead,
    icon: <ContactsIcon />,
    label: i18n('entities.contact.menu'),
  },
  {
    path: '/event',
    permissionRequired: permissions.eventRead,
    icon: <EventsIcon />,
    label: i18n('entities.event.menu'),
  },
  {
    path: '/data',
    permissionRequired: permissions.dataRead,
    icon: <DataIcon />,
    label: i18n('entities.data.menu'),
  },
  {
    path: '/admin',
    permissionRequired: permissions.adminRead,
    icon: <AdminIcon />,
    label: i18n('entities.admin.menu'),
  },
  {
    path: '/help',
    permissionRequired: permissions.helpRead,
    icon: <HelpIcon />,
    label: i18n('entities.help.menu'),
  },

].filter(Boolean);
