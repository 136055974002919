import HelpService from 'src/modules/help/helpService';
import Errors from 'src/modules/shared/error/errors';

const prefix = 'TOPIC_FORM';

const helpFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  doInit: (id) => async (dispatch) => {
    try {
      dispatch({
        type: helpFormActions.INIT_STARTED,
      });

      let record = {};

      const isEdit = Boolean(id);

      if (isEdit) {
        record = await HelpService.find(id);
      }

      dispatch({
        type: helpFormActions.INIT_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: helpFormActions.INIT_ERROR,
      });

    }
  },

  doCreate: (values) => async (dispatch) => {
    try {
      dispatch({
        type: helpFormActions.CREATE_STARTED,
      });

      const record = await HelpService.create(values);

      dispatch({
        type: helpFormActions.CREATE_SUCCESS,
        payload: record,
      });

      // Message.success(
      //   i18n('entities.help.create.success'),
      // );

    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: helpFormActions.CREATE_ERROR,
      });
    }
  },

  doUpdate: (id, values) => async (dispatch, getState) => {
    try {
      dispatch({
        type: helpFormActions.UPDATE_STARTED,
      });

      await HelpService.update(id, values);

      dispatch({
        type: helpFormActions.UPDATE_SUCCESS,
      });

      // Message.success(
      //   i18n('entities.help.update.success'),
      // );

    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: helpFormActions.UPDATE_ERROR,
      });
    }
  },
};

export default helpFormActions;
