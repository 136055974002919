import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/marketSegment/importer/marketSegmentImporterSelectors';
import MarketSegmentDataService from 'src/modules/marketSegmentData/marketSegmentDataService';
import fields from 'src/modules/marketSegmentData/importer/marketSegmentDataImporterFields';
import { i18n } from 'src/i18n';

const marketSegmentDataImporterActions = importerActions(
  'MARKETSEGMENTDATA_IMPORTER',
  selectors,
  MarketSegmentDataService.import,
  fields,
  i18n('entities.marketSegmentData.importer.fileName'),
);

export default marketSegmentDataImporterActions;