import HelpService from 'src/modules/help/helpService';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';

const prefix = 'TOPIC_VIEW';

const helpViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: helpViewActions.FIND_STARTED,
      });

      const record = await HelpService.find(id);

      dispatch({
        type: helpViewActions.FIND_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: helpViewActions.FIND_ERROR,
      });

      getHistory().push('/reference');
    }
  },

  doFindByName: (name) => async (dispatch) => {
    try {
      dispatch({
        type: helpViewActions.FIND_STARTED,
      });

      const record = await HelpService.findByName(name);

      dispatch({
        type: helpViewActions.FIND_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: helpViewActions.FIND_ERROR,
      });

    }
  },

};

export default helpViewActions;
