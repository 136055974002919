import list from 'src/modules/deck/list/deckListReducers';
import form from 'src/modules/deck/form/deckFormReducers';
import view from 'src/modules/deck/view/deckViewReducers';
import destroy from 'src/modules/deck/destroy/deckDestroyReducers';
import importerReducer from 'src/modules/deck/importer/deckImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
