import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/company/importer/companyImporterSelectors';
import CompanyService from 'src/modules/company/companyService';
import fields from 'src/modules/company/importer/companyImporterFields';
import { i18n } from 'src/i18n';

const companyImporterActions = importerActions(
  'COMPANY_IMPORTER',
  selectors,
  CompanyService.import,
  fields,
  i18n('entities.company.importer.fileName'),
);

export default companyImporterActions;