const contactEnumerators = {
  contactType: [
    'Customer',
    'Prospect',
    'Expert',
    'Internal Stakeholder',
    'Vendor',
    'Partner',
    'Investor',
    'Potential Investor',
    'Advisor',
    'Research Participant',
    'Advocate',
    'Influencer',
    'Competitor',
    'Other'
  ],
};

export default contactEnumerators;
