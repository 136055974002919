import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import contactEnumerators from 'src/modules/contact/contactEnumerators';

export default [
  {
    name: 'firstName',
    label: i18n('entities.contact.fields.firstName'),
    schema: schemas.string(
      i18n('entities.contact.fields.firstName'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'lastName',
    label: i18n('entities.contact.fields.lastName'),
    schema: schemas.string(
      i18n('entities.contact.fields.lastName'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'phone',
    label: i18n('entities.contact.fields.phone'),
    schema: schemas.string(
      i18n('entities.contact.fields.phone'),
      {
        
      },
    ),
  },
  {
    name: 'email',
    label: i18n('entities.contact.fields.email'),
    schema: schemas.string(
      i18n('entities.contact.fields.email'),
      {
        
      },
    ),
  },
  {
    name: 'company',
    label: i18n('entities.contact.fields.company'),
    schema: schemas.string(
      i18n('entities.contact.fields.company'),
      {
        
      },
    ),
  },
  {
    name: 'contacType',
    label: i18n('entities.contact.fields.contactType'),
    schema: schemas.enumerator(
      i18n('entities.contact.fields.contactType'),
      {
        "options": contactEnumerators.contactType
      },
    ),
  },
  {
    name: 'website',
    label: i18n('entities.contact.fields.website'),
    schema: schemas.string(
      i18n('entities.contact.fields.website'),
      {
        
      },
    ),
  },
  {
    name: 'linkedIn',
    label: i18n('entities.contact.fields.linkedIn'),
    schema: schemas.string(
      i18n('entities.contact.fields.linkedIn'),
      {
        
      },
    ),
  },
  {
    name: 'notes',
    label: i18n('entities.contact.fields.notes'),
    schema: schemas.string(
      i18n('entities.contact.fields.notes'),
      {
        
      },
    ),
  },
 
];