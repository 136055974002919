import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/board/importer/boardImporterSelectors';
import BoardService from 'src/modules/board/boardService';
import fields from 'src/modules/board/importer/boardImporterFields';
import { i18n } from 'src/i18n';

const boardImporterActions = importerActions(
  'BOARD_IMPORTER',
  selectors,
  BoardService.import,
  fields,
  i18n('entities.board.importer.fileName'),
);

export default boardImporterActions;