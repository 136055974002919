import list from 'src/modules/marketSegmentData/list/marketSegmentDataListReducers';
import form from 'src/modules/marketSegmentData/form/marketSegmentDataFormReducers';
import view from 'src/modules/marketSegmentData/view/marketSegmentDataViewReducers';
import destroy from 'src/modules/marketSegmentData/destroy/marketSegmentDataDestroyReducers';
import importerReducer from 'src/modules/marketSegmentData/importer/marketSegmentDataImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
