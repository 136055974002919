import list from 'src/modules/topic/list/topicListReducers';
import form from 'src/modules/topic/form/topicFormReducers';
import view from 'src/modules/topic/view/topicViewReducers';
import destroy from 'src/modules/topic/destroy/topicDestroyReducers';
import importerReducer from 'src/modules/topic/importer/topicImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
