import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/definition/importer/definitionImporterSelectors';
import DefinitionService from 'src/modules/definition/definitionService';
import fields from 'src/modules/definition/importer/definitionImporterFields';
import { i18n } from 'src/i18n';

const definitionImporterActions = importerActions(
  'BOOKMARK_IMPORTER',
  selectors,
  DefinitionService.import,
  fields,
  i18n('entities.definition.importer.fileName'),
);

export default definitionImporterActions;