import list from 'src/modules/cohort/list/cohortListReducers';
import form from 'src/modules/cohort/form/cohortFormReducers';
import view from 'src/modules/cohort/view/cohortViewReducers';
import destroy from 'src/modules/cohort/destroy/cohortDestroyReducers';
import importerReducer from 'src/modules/cohort/importer/cohortImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
