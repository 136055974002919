import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.marketSegment.fields.id'),
  },
  {
    name: 'name',
    label: i18n('entities.marketSegment.fields.name'),
  },
  {
    name: 'industries',
    label: i18n('entities.marketSegment.fields.industries'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'countries',
    label: i18n('entities.marketSegment.fields.countries'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'employeeCount',
    label: i18n('entities.marketSegment.fields.employeeCount'),
    render: exporterRenders.stringArray(),
  },
  {
    name: 'valueModifier',
    label: i18n('entities.marketSegment.fields.valueModifier'),
  },
  {
    name: 'sOMModifier',
    label: i18n('entities.marketSegment.fields.sOMModifier'),
  },
  {
    name: 'sAMModifier',
    label: i18n('entities.marketSegment.fields.sAMModifier'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.marketSegment.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.marketSegment.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
