import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/deck/importer/deckImporterSelectors';
import DeckService from 'src/modules/deck/deckService';
import fields from 'src/modules/deck/importer/deckImporterFields';
import { i18n } from 'src/i18n';

const deckImporterActions = importerActions(
  'DECK_IMPORTER',
  selectors,
  DeckService.import,
  fields,
  i18n('entities.deck.importer.fileName'),
);

export default deckImporterActions;