import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.company.fields.id'),
  },
  {
    name: 'name',
    label: i18n('entities.company.fields.name'),
  },
  {
    name: 'description',
    label: i18n('entities.company.fields.description'),
  },
  {
    name: 'employeeCount',
    label: i18n('entities.company.fields.employeeCount'),
  },
  {
    name: 'industry',
    label: i18n('entities.company.fields.industry'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'country',
    label: i18n('entities.company.fields.country'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'website',
    label: i18n('entities.company.fields.website'),
  },
  {
    name: 'founded',
    label: i18n('entities.company.fields.founded'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.company.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.company.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
