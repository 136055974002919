import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/bookmark/importer/bookmarkImporterSelectors';
import BookmarkService from 'src/modules/bookmark/bookmarkService';
import fields from 'src/modules/bookmark/importer/bookmarkImporterFields';
import { i18n } from 'src/i18n';

const bookmarkImporterActions = importerActions(
  'BOOKMARK_IMPORTER',
  selectors,
  BookmarkService.import,
  fields,
  i18n('entities.bookmark.importer.fileName'),
);

export default bookmarkImporterActions;