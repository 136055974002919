import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/topic/importer/topicImporterSelectors';
import TopicService from 'src/modules/topic/topicService';
import fields from 'src/modules/topic/importer/topicImporterFields';
import { i18n } from 'src/i18n';

const topicImporterActions = importerActions(
  'TOPIC_IMPORTER',
  selectors,
  TopicService.import,
  fields,
  i18n('entities.topic.importer.fileName'),
);

export default topicImporterActions;