import Permissions from 'src/security/permissions';
import config from 'src/config';
const permissions = Permissions.values;

const privateRoutes = [
  {
    path: '/',
    loader: () =>
      //import('src/view/dashboard/DashboardPage'),
      import('src/view/site/Home/ViewHome'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/profile',
    loader: () => import('src/view/auth/ProfileFormPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/password-change',
    loader: () =>
      import('src/view/auth/PasswordChangeFormPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/tenant',
    loader: () =>
      import('src/view/tenant/list/TenantListPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/tenant/new',
    loader: () =>
      import('src/view/tenant/form/TenantFormPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/tenant/:id/edit',
    loader: () =>
      import('src/view/tenant/form/TenantFormPage'),
    permissionRequired: null,
    exact: true,
  },

  config.isPlanEnabled && {
    path: '/plan',
    loader: () => import('src/view/plan/PlanPage'),
    permissionRequired: permissions.planRead,
    exact: true,
  },

  {
    path: '/user',
    loader: () => import('src/view/site/Users/ListUsers'),
    permissionRequired: permissions.userRead,
    exact: true,
  },

  {
    path: '/user/new',
    loader: () => import('src/view/site/Users/NewUser'),
    permissionRequired: permissions.userCreate,
    exact: true,
  },

  {
    path: '/user/importer',
    loader: () =>
      import('src/view/user/importer/UserImporterPage'),
    permissionRequired: permissions.userImport,
    exact: true,
  },
  {
    path: '/user/:id/edit',
    loader: () => import('src/view/site/Users/EditUser'),
    permissionRequired: permissions.userEdit,
    exact: true,
  },
  {
    path: '/user/:id',
    loader: () => import('src/view/site/Users/ViewUser'),
    permissionRequired: permissions.userRead,
    exact: true,
  },

  {
    path: '/audit-logs',
    loader: () => import('src/view/site/Audit/ListAudit'),
    permissionRequired: permissions.auditLogRead,
  },

  {
    path: '/settings',
    loader: () =>
      import('src/view/site/Settings/EditSettings'),
    permissionRequired: permissions.settingsEdit,
  },


  
  {
    path: '/research',
    loader: () =>
      import('src/view/site/Documents/ListDocuments'),
    permissionRequired: permissions.researchRead,
    exact: true,
  },
  {
    path: '/research/new',
    loader: () =>
      import('src/view/site/Documents/EditDocument'),
    permissionRequired: permissions.researchCreate,
    exact: true,
  },
  {
    path: '/research/importer',
    loader: () =>
      import(
        'src/view/research/importer/ResearchImporterPage'
      ),
    permissionRequired: permissions.researchImport,
    exact: true,
  },
  {
    path: '/research/:id/edit',
    loader: () =>
      import('src/view/site/Documents/EditDocument'),
    permissionRequired: permissions.researchEdit,
    exact: true,
  },
  {
    path: '/research/:id',
    loader: () =>
    //  import('src/view/research/view/ResearchViewPage'),
    import('src/view/site/Documents/ViewDocument'),
    permissionRequired: permissions.researchRead,
    exact: true,
  },

  {
    path: '/definition',
    loader: () =>
      import('src/view/site/Definitions/ListDefinitions'),
    permissionRequired: permissions.definitionRead,
    exact: true,
  },
  {
    path: '/definition/new',
    loader: () =>
      import('src/view/site/Definitions/EditDefinition'),
    permissionRequired: permissions.definitionCreate,
    exact: true,
  },
  {
    path: '/definition/importer',
    loader: () =>
      import(
        'src/view/definition/importer/DefinitionImporterPage'
      ),
    permissionRequired: permissions.definitionImport,
    exact: true,
  },
  {
    path: '/definition/:id/edit',
    loader: () =>
      import('src/view/site/Definitions/EditDefinition'),
    permissionRequired: permissions.definitionEdit,
    exact: true,
  },
  {
    path: '/definition/:id',
    loader: () =>
      import('src/view/site/Definitions/ViewDefinition'),
    permissionRequired: permissions.definitionRead,
    exact: true,
  },

  // Topic
  {
    path: '/topic',
    loader: () =>
      import('src/view/site/Topics/ListTopic'),
    permissionRequired: permissions.topicRead,
    exact: true,
  },
  {
    path: '/topic/new',
    loader: () =>
      import('src/view/site/Topics/EditTopic'),
    permissionRequired: permissions.topicCreate,
    exact: true,
  },
  {
    path: '/topic/importer',
    loader: () =>
      import(
        'src/view/topic/importer/TopicImporterPage'
      ),
    permissionRequired: permissions.topicImport,
    exact: true,
  },
  {
    path: '/topic/:id/edit',
    loader: () =>
      import('src/view/site/Topics/EditTopic'),
    permissionRequired: permissions.topicEdit,
    exact: true,
  },
  {
    path: '/topic/:id',
    loader: () =>
      import('src/view/site/Topics/ViewTopic'),
    permissionRequired: permissions.definitionRead,
    exact: true,
  },
  {
    path: '/topic/byname/:name',
    loader: () =>
      import('src/view/site/Topics/ListTopic'),
    permissionRequired: permissions.definitionRead,
    exact: true,
  },

  {
    path: '/insight',
    loader: () =>
      import('src/view/site/Insights/ListInsights'),
    permissionRequired: permissions.insightRead,
    exact: true,
  },
  {
    path: '/insight/new',
    loader: () =>
      import('src/view/site/Insights/EditInsight'),
    permissionRequired: permissions.insightCreate,
    exact: true,
  },
  {
    path: '/insight/importer',
    loader: () =>
      import(
        'src/view/insight/importer/InsightImporterPage'
      ),
    permissionRequired: permissions.insightImport,
    exact: true,
  },
  {
    path: '/insight/:id/edit',
    loader: () =>
      import('src/view/site/Insights/EditInsight'),
    permissionRequired: permissions.insightEdit,
    exact: true,
  },
  {
    path: '/insight/:id',
    loader: () =>
      import('src/view/site/Insights/ViewInsight'),
    permissionRequired: permissions.insightRead,
    exact: true,
  },

  {
    path: '/product',
    loader: () =>
      import('src/view/site/Products/ListProducts'),
    permissionRequired: permissions.productRead,
    exact: true,
  },
  {
    path: '/product/new',
    loader: () =>
      import('src/view/site/Products/EditProduct'),
    permissionRequired: permissions.productCreate,
    exact: true,
  },
  {
    path: '/product/importer',
    loader: () =>
      import(
        'src/view/product/importer/ProductImporterPage'
      ),
    permissionRequired: permissions.productImport,
    exact: true,
  },
  {
    path: '/product/:id/edit',
    loader: () =>
      import('src/view/site/Products/EditProduct'),
    permissionRequired: permissions.productEdit,
    exact: true,
  },
  {
    path: '/product/:id',
    loader: () =>
      import('src/view/site/Products/ViewProduct'),
    permissionRequired: permissions.productRead,
    exact: true,
  },
  {
    path: '/data',
    loader: () =>
      import('src/view/site/Data/ListData'),
    permissionRequired: permissions.dataRead,
    exact: true,
  },
  {
    path: '/admin',
    loader: () =>
      import('src/view/site/Admin/ListAdmin'),
    permissionRequired: permissions.adminRead,
    exact: true,
  },

  {
    path: '/company',
    loader: () =>
      import('src/view/site/Companies/ListCompanies'),
    permissionRequired: permissions.companyRead,
    exact: true,
  },
  {
    path: '/company/new',
    loader: () =>
      import('src/view/site/Companies/EditCompany'),
    permissionRequired: permissions.companyCreate,
    exact: true,
  },
  {
    path: '/company/importer',
    loader: () =>
      import(
        'src/view/company/importer/CompanyImporterPage'
      ),
    permissionRequired: permissions.companyImport,
    exact: true,
  },
  {
    path: '/company/:id/edit',
    loader: () =>
      import('src/view/site/Companies/EditCompany'),
    permissionRequired: permissions.companyEdit,
    exact: true,
  },
  {
    path: '/company/:id',
    loader: () =>
      import('src/view/site/Companies/ViewCompany'),
    permissionRequired: permissions.companyRead,
    exact: true,
  },

  {
    path: '/industry',
    loader: () =>
      import('src/view/site/Industries/ListIndustries'),
    permissionRequired: permissions.industryRead,
    exact: true,
  },
  {
    path: '/industry/new',
    loader: () =>
      import('src/view/site/Industries/EditIndustry'),
    permissionRequired: permissions.industryCreate,
    exact: true,
  },
  {
    path: '/industry/importer',
    loader: () =>
      import(
        'src/view/industry/importer/IndustryImporterPage'
      ),
    permissionRequired: permissions.industryImport,
    exact: true,
  },
  {
    path: '/industry/:id/edit',
    loader: () =>
      import('src/view/site/Industries/EditIndustry'),
    permissionRequired: permissions.industryEdit,
    exact: true,
  },
  {
    path: '/industry/:id',
    loader: () =>
      import('src/view/site/Industries/ViewIndustry'),
    permissionRequired: permissions.industryRead,
    exact: true,
  },

  {
    path: '/country',
    loader: () =>
      import('src/view/site/Countries/ListCountries'),
    permissionRequired: permissions.countryRead,
    exact: true,
  },
  {
    path: '/country/new',
    loader: () =>
      import('src/view/site/Countries/EditCountry'),
    permissionRequired: permissions.countryCreate,
    exact: true,
  },
  {
    path: '/country/importer',
    loader: () =>
      import(
        'src/view/country/importer/CountryImporterPage'
      ),
    permissionRequired: permissions.countryImport,
    exact: true,
  },
  {
    path: '/country/:id/edit',
    loader: () =>
      import('src/view/site/Countries/EditCountry'),
    permissionRequired: permissions.countryEdit,
    exact: true,
  },
  {
    path: '/country/:id',
    loader: () =>
      import('src/view/site/Countries/ViewCountry'),
    permissionRequired: permissions.countryRead,
    exact: true,
  },

  {
    path: '/jobTitle',
    loader: () =>
      import('src/view/site/JobTitles/ListJobTitle'),
    permissionRequired: permissions.jobTitleRead,
    exact: true,
  },
  {
    path: '/jobTitle/new',
    loader: () =>
      import('src/view/site/JobTitles/EditJobTitle'),
    permissionRequired: permissions.jobTitleCreate,
    exact: true,
  },
  {
    path: '/jobTitle/importer',
    loader: () =>
      import(
        'src/view/jobTitle/importer/JobTitleImporterPage'
      ),
    permissionRequired: permissions.jobTitleImport,
    exact: true,
  },
  {
    path: '/jobTitle/:id/edit',
    loader: () =>
      import('src/view/site/JobTitles/EditJobTitle'),
    permissionRequired: permissions.jobTitleEdit,
    exact: true,
  },
  {
    path: '/jobTitle/:id',
    loader: () =>
      import('src/view/site/JobTitles/ViewJobTitle'),
    permissionRequired: permissions.jobTitleRead,
    exact: true,
  },

  {
    path: '/model',
    loader: () =>
      import('src/view/site/Models/ListModels'),
    permissionRequired: permissions.modelRead,
    exact: true,
  },
  {
    path: '/model/new',
    loader: () =>
      import('src/view/site/Models/EditModel'),
    permissionRequired: permissions.modelCreate,
    exact: true,
  },
  {
    path: '/model/importer',
    loader: () =>
      import(
        'src/view/model/importer/ModelImporterPage'
      ),
    permissionRequired: permissions.modelImport,
    exact: true,
  },
  {
    path: '/model/:id/edit',
    loader: () =>
      import('src/view/site/Models/EditModel'),
    permissionRequired: permissions.modelEdit,
    exact: true,
  },
  {
    path: '/model/:id',
    loader: () =>
      import('src/view/site/Models/ViewModel'),
    permissionRequired: permissions.modelRead,
    exact: true,
  },

  {
    path: '/slide',
    loader: () =>
      import('src/view/site/Slides/ListSlides'),
    permissionRequired: permissions.slideRead,
    exact: true,
  },
  {
    path: '/slide/new',
    loader: () =>
      import('src/view/site/Slides/EditSlide'),
    permissionRequired: permissions.slideCreate,
    exact: true,
  },
  {
    path: '/slide/importer',
    loader: () =>
      import(
        'src/view/slide/importer/SlideImporterPage'
      ),
    permissionRequired: permissions.slideImport,
    exact: true,
  },
  {
    path: '/slide/:id/edit',
    loader: () =>
      import('src/view/site/Slides/EditSlide'),
    permissionRequired: permissions.slideEdit,
    exact: true,
  },
  {
    path: '/slide/:id',
    loader: () =>
      import('src/view/site/Slides/ViewSlide'),
    permissionRequired: permissions.slideRead,
    exact: true,
  },
  {
    path: '/deck',
    loader: () =>
      import('src/view/site/Decks/ListDecks'),
    permissionRequired: permissions.deckRead,
    exact: true,
  },
  {
    path: '/deck/new',
    loader: () =>
      import('src/view/site/Decks/EditDeck'),
    permissionRequired: permissions.deckCreate,
    exact: true,
  },
  {
    path: '/deck/importer',
    loader: () =>
      import(
        'src/view/deck/importer/DeckImporterPage'
      ),
    permissionRequired: permissions.deckImport,
    exact: true,
  },
  {
    path: '/deck/:id/edit',
    loader: () =>
      import('src/view/site/Decks/EditDeck'),
    permissionRequired: permissions.deckEdit,
    exact: true,
  },
  {
    path: '/deck/:id',
    loader: () =>
      import('src/view/site/Decks/ViewDeck'),
    permissionRequired: permissions.deckRead,
    exact: true,
  },
  {
    path: '/market-segment',
    loader: () =>
      import('src/view/site/MarketSegments/ListMarketSegments'),
    permissionRequired: permissions.marketSegmentRead,
    exact: true,
  },
  {
    path: '/market-segment/new',
    loader: () =>
      import('src/view/site/MarketSegments/EditMarketSegment'),
    permissionRequired: permissions.marketSegmentCreate,
    exact: true,
  },
  {
    path: '/market-segment/importer',
    loader: () =>
      import(
        'src/view/marketSegment/importer/MarketSegmentImporterPage'
      ),
    permissionRequired: permissions.marketSegmentImport,
    exact: true,
  },
  {
    path: '/market-segment/:id/edit',
    loader: () =>
      import('src/view/site/MarketSegments/EditMarketSegment'),
    permissionRequired: permissions.marketSegmentEdit,
    exact: true,
  },
  {
    path: '/market-segment/:id',
    loader: () =>
      import('src/view/site/MarketSegments/ViewMarketSegment'),
    permissionRequired: permissions.marketSegmentRead,
    exact: true,
  },

  {
    path: '/cohort',
    loader: () =>
      import('src/view/site/Cohorts/ListCohorts'),
    permissionRequired: permissions.cohortRead,
    exact: true,
  },
  {
    path: '/cohort/new',
    loader: () =>
      import('src/view/site/Cohorts/EditCohort'),
    permissionRequired: permissions.cohortCreate,
    exact: true,
  },
  {
    path: '/cohort/importer',
    loader: () =>
      import(
        'src/view/cohort/importer/CohortImporterPage'
      ),
    permissionRequired: permissions.cohortImport,
    exact: true,
  },
  {
    path: '/cohort/:id/edit',
    loader: () =>
      import('src/view/site/Cohorts/EditCohort'),
    permissionRequired: permissions.cohortEdit,
    exact: true,
  },
  {
    path: '/cohort/:id',
    loader: () =>
      import('src/view/site/Cohorts/ViewCohort'),
    permissionRequired: permissions.cohortRead,
    exact: true,
  },

  {
    path: '/market-segment-data',
    loader: () =>
      import('src/view/site/MarketSegmentData/ListMarketSegmentData'),
    permissionRequired: permissions.marketSegmentDataRead,
    exact: true,
  },
  {
    path: '/market-segment-data/new',
    loader: () =>
      import('src/view/site/MarketSegmentData/EditMarketSegmentData'),
    permissionRequired: permissions.marketSegmentDataCreate,
    exact: true,
  },
  {
    path: '/market-segment-data/importer',
    loader: () =>
      import(
        'src/view/marketSegmentData/importer/MarketSegmentDataImporterPage'
      ),
    permissionRequired: permissions.marketSegmentDataImport,
    exact: true,
  },
  {
    path: '/market-segment-data/:id/edit',
    loader: () =>
      import('src/view/site/MarketSegmentData/EditMarketSegmentData'),
    permissionRequired: permissions.marketSegmentDataEdit,
    exact: true,
  },
  {
    path: '/market-segment-data/:id',
    loader: () =>
      import('src/view/site/MarketSegmentData/ViewMarketSegmentData'),
    permissionRequired: permissions.marketSegmentDataRead,
    exact: true,
  },


  {
    path: '/tag',
    loader: () =>
      import('src/view/site/Tags/ListTags'),
    permissionRequired: permissions.tagRead,
    exact: true,
  },
  {
    path: '/tag/new',
    loader: () =>
      import('src/view/site/Tags/EditTag'),
    permissionRequired: permissions.tagCreate,
    exact: true,
  },
  {
    path: '/tag/importer',
    loader: () =>
      import(
        'src/view/tag/importer/TagImporterPage'
      ),
    permissionRequired: permissions.tagImport,
    exact: true,
  },
  {
    path: '/tag/:id/edit',
    loader: () =>
      import('src/view/site/Tags/EditTag'),
    permissionRequired: permissions.tagEdit,
    exact: true,
  },
  {
    path: '/tag/:id',
    loader: () =>
      import('src/view/site/Tags/ViewTag'),
    permissionRequired: permissions.tagRead,
    exact: true,
  },

  ///
  {
    path: '/bookmark',
    loader: () =>
      import('src/view/site/Bookmarks/ListBookmarks'),
    permissionRequired: permissions.bookmarkRead,
    exact: true,
  },
  {
    path: '/bookmark/new',
    loader: () =>
      import('src/view/site/Bookmarks/EditBookmark'),
    permissionRequired: permissions.bookmarkCreate,
    exact: true,
  },
  {
    path: '/bookmark/importer',
    loader: () =>
      import(
        'src/view/bookmark/importer/BookmarkImporterPage'
      ),
    permissionRequired: permissions.bookmarkImport,
    exact: true,
  },
  {
    path: '/bookmark/:id/edit',
    loader: () =>
      import('src/view/site/Bookmarks/EditBookmark'),
    permissionRequired: permissions.bookmarkEdit,
    exact: true,
  },
  {
    path: '/bookmark/:id',
    loader: () =>
      import('src/view/site/Bookmarks/ViewBookmark'),
    permissionRequired: permissions.bookmarkRead,
    exact: true,
  },

  {
    path: '/board',
    loader: () =>
      import('src/view/site/Boards/ViewBoardBoard'),
    permissionRequired: permissions.boardRead,
    exact: true,
  },
  {
    path: '/board/new',
    loader: () =>
      import('src/view/site/Boards/EditBoard'),
    permissionRequired: permissions.boardCreate,
    exact: true,
  },
  {
    path: '/board/importer',
    loader: () =>
      import(
        'src/view/board/importer/BoardImporterPage'
      ),
    permissionRequired: permissions.boardImport,
    exact: true,
  },
  {
    path: '/board/:id/edit',
    loader: () =>
      import('src/view/site/Boards/EditBoard'),
    permissionRequired: permissions.boardEdit,
    exact: true,
  },
  {
    path: '/board/:id',
    loader: () =>
      import('src/view/site/Boards/ViewBoardBoard'),
    permissionRequired: permissions.boardRead,
    exact: true,
  },

  {
    path: '/customer',
    loader: () =>
      import('src/view/site/Customers/ListCustomers'),
    permissionRequired: permissions.customerRead,
    exact: true,
  },
  {
    path: '/customer/new',
    loader: () =>
      import('src/view/site/Customers/EditCustomer'),
    permissionRequired: permissions.customerCreate,
    exact: true,
  },
  {
    path: '/customer/importer',
    loader: () =>
      import(
        'src/view/customer/importer/CustomerImporterPage'
      ),
    permissionRequired: permissions.customerImport,
    exact: true,
  },
  {
    path: '/customer/:id/edit',
    loader: () =>
      import('src/view/site/Customers/EditCustomer'),
    permissionRequired: permissions.customerEdit,
    exact: true,
  },
  {
    path: '/customer/:id',
    loader: () =>
      import('src/view/site/Customers/ViewCustomer'),
    permissionRequired: permissions.customerRead,
    exact: true,
  },
  {
    path: '/customer',
    loader: () =>
      import('src/view/customer/list/CustomerListPage'),
    permissionRequired: permissions.customerRead,
    exact: true,
  },
  {
    path: '/contact',
    loader: () =>
      import('src/view/site/Contacts/ListContacts'),
    permissionRequired: permissions.contactRead,
    exact: true,
  },
  {
    path: '/contact/new',
    loader: () =>
      import('src/view/site/Contacts/EditContact'),
    permissionRequired: permissions.contactCreate,
    exact: true,
  },
  {
    path: '/contact/importer',
    loader: () =>
      import(
        'src/view/contact/importer/ContactImporterPage'
      ),
    permissionRequired: permissions.contactImport,
    exact: true,
  },
  {
    path: '/contact/:id/edit',
    loader: () =>
      import('src/view/site/Contacts/EditContact'),
    permissionRequired: permissions.contactEdit,
    exact: true,
  },
  {
    path: '/contact/:id',
    loader: () =>
      import('src/view/site/Contacts/ViewContact'),
    permissionRequired: permissions.contactRead,
    exact: true,
  },
  {
    path: '/contact',
    loader: () =>
      import('src/view/contact/list/ContactListPage'),
    permissionRequired: permissions.contactRead,
    exact: true,
  },
  {
    path: '/event',
    loader: () =>
      import('src/view/site/Events/ListEvents'),
    permissionRequired: permissions.eventRead,
    exact: true,
  },
  {
    path: '/event/new',
    loader: () =>
      import('src/view/site/Events/EditEvent'),
    permissionRequired: permissions.eventCreate,
    exact: true,
  },
  {
    path: '/event/importer',
    loader: () =>
      import(
        'src/view/event/importer/EventImporterPage'
      ),
    permissionRequired: permissions.eventImport,
    exact: true,
  },
  {
    path: '/event/:id/edit',
    loader: () =>
      import('src/view/site/Events/EditEvent'),
    permissionRequired: permissions.eventEdit,
    exact: true,
  },
  {
    path: '/event/:id',
    loader: () =>
      import('src/view/site/Events/ViewEvent'),
    permissionRequired: permissions.eventRead,
    exact: true,
  },
  {
    path: '/event',
    loader: () =>
      import('src/view/event/list/EventListPage'),
    permissionRequired: permissions.eventRead,
    exact: true,
  },
  
  {
    path: '/feedboard',
    loader: () =>
      import('src/view/site/Feeds/ListFeedBoard'),
    permissionRequired: permissions.feedRead,
    exact: true,
  },
  {
    path: '/feed',
    loader: () =>
      import('src/view/site/Feeds/ListFeeds'),
    permissionRequired: permissions.feedRead,
    exact: true,
  },
  {
    path: '/feed/new',
    loader: () =>
      import('src/view/site/Feeds/EditFeed'),
    permissionRequired: permissions.feedCreate,
    exact: true,
  },
  {
    path: '/feed/importer',
    loader: () =>
      import(
        'src/view/feed/importer/FeedImporterPage'
      ),
    permissionRequired: permissions.feedImport,
    exact: true,
  },
  {
    path: '/feed/:id/edit',
    loader: () =>
      import('src/view/site/Feeds/EditFeed'),
    permissionRequired: permissions.feedEdit,
    exact: true,
  },
  {
    path: '/feed/:id',
    loader: () =>
      import('src/view/site/Feeds/ViewFeed'),
    permissionRequired: permissions.feedRead,
    exact: true,
  },
  {
    path: '/feed',
    loader: () =>
      import('src/view/feed/list/FeedListPage'),
    permissionRequired: permissions.feedRead,
    exact: true,
  },
  {
    path: '/reference',
    loader: () =>
      import('src/view/site/Pages/ViewPage'),
    permissionRequired: permissions.referenceRead,
    exact: true,
  },
  {
    path: '/reference/:name',
    loader: () =>
      import('src/view/site/Pages/ViewPage'),
    permissionRequired: permissions.referenceRead,
    exact: true,
  },
  {
    path: '/help',
    loader: () =>
      import('src/view/site/Help/ViewHelp'),
    permissionRequired: permissions.helpRead,
    exact: true,
  },
  {
    path: '/help/:name',
    loader: () =>
      import('src/view/site/Help/ViewHelp'),
    permissionRequired: permissions.helpRead,
    exact: true,
  },
].filter(Boolean);

const publicRoutes = [
  {
    path: '/auth/signin',
    loader: () => import('src/view/auth/SigninPage'),
  },
  {
    path: '/auth/signup',
    loader: () => import('src/view/auth/SignupPage'),
  },
  {
    path: '/auth/forgot-password',
    loader: () =>
      import('src/view/auth/ForgotPasswordPage'),
  },
].filter(Boolean);

const emptyTenantRoutes = [
  {
    path: '/auth/tenant',
    loader: () => import('src/view/auth/TenantPage'),
  },
].filter(Boolean);

const emptyPermissionsRoutes = [
  {
    path: '/auth/empty-permissions',
    loader: () =>
      import('src/view/auth/EmptyPermissionsPage'),
  },
].filter(Boolean);

const emailUnverifiedRoutes = [
  {
    path: '/auth/email-unverified',
    loader: () =>
      import('src/view/auth/EmailUnverifiedPage'),
  },
].filter(Boolean);

const simpleRoutes = [
  {
    path: '/auth/password-reset',
    loader: () => import('src/view/auth/PasswordResetPage'),
  },
  {
    path: '/auth/invitation',
    loader: () => import('src/view/auth/InvitationPage'),
  },
  {
    path: '/auth/verify-email',
    loader: () => import('src/view/auth/VerifyEmailPage'),
  },
  {
    path: '/403',
    loader: () =>
      import('src/view/shared/errors/Error403Page'),
  },
  {
    path: '/500',
    loader: () =>
      import('src/view/shared/errors/Error500Page'),
  },
  {
    path: '**',
    loader: () =>
      import('src/view/shared/errors/Error404Page'),
  },
].filter(Boolean);

export default {
  privateRoutes,
  publicRoutes,
  emptyTenantRoutes,
  emptyPermissionsRoutes,
  emailUnverifiedRoutes,
  simpleRoutes,
};
