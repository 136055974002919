import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/industry/importer/industryImporterSelectors';
import IndustryService from 'src/modules/industry/industryService';
import fields from 'src/modules/industry/importer/industryImporterFields';
import { i18n } from 'src/i18n';

const industryImporterActions = importerActions(
  'INDUSTRY_IMPORTER',
  selectors,
  IndustryService.import,
  fields,
  i18n('entities.industry.importer.fileName'),
);

export default industryImporterActions;