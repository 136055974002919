import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.product.fields.id'),
  },
  {
    name: 'productName',
    label: i18n('entities.product.fields.productName'),
  },
  {
    name: 'productDescription',
    label: i18n('entities.product.fields.productDescription'),
  },
  {
    name: 'productStatus',
    label: i18n('entities.product.fields.productStatus'),
  },
  {
    name: 'productLaunchDate',
    label: i18n('entities.product.fields.productLaunchDate'),
  },
  {
    name: 'growthRate',
    label: i18n('entities.product.fields.growthRate'),
  },
  {
    name: 'currentRevenue',
    label: i18n('entities.product.fields.currentRevenue'),
  },
  {
    name: 'averageValue1to10',
    label: i18n('entities.product.fields.averageValue1to10'),
  },
  {
    name: 'averageValue11to50',
    label: i18n('entities.product.fields.averageValue11to50'),
  },
  {
    name: 'averageValue51to200',
    label: i18n('entities.product.fields.averageValue51to200'),
  },
  {
    name: 'averageValue201to500',
    label: i18n('entities.product.fields.averageValue201to500'),
  },
  {
    name: 'averageValue501to1090',
    label: i18n('entities.product.fields.averageValue501to1000'),
  },
  {
    name: 'averageValue1001to5000',
    label: i18n('entities.product.fields.averageValue1001to5000'),
  },
  {
    name: 'averageValue5001to10000',
    label: i18n('entities.product.fields.averageValue5001to10000'),
  },
  {
    name: 'averageValue10001+',
    label: i18n('entities.product.fields.averageValue10001+'),
  },
  {
    name: 'marketSegments',
    label: i18n('entities.product.fields.marketSegments'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.product.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.product.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
