/**
 * List of Plans
 */
class Plans {
  static get values() {
    return {
      free: 'free',
      essentials: 'essentials',
      pro: 'pro',
    };
  }
}

export default Plans;
