import Roles from 'src/security/roles';
import Plans from 'src/security/plans';
import Storage from 'src/security/storage';

const storage = Storage.values;
const roles = Roles.values;
const plans = Plans.values;

class Permissions {
  static get values() {
    return {
      tenantEdit: {
        id: 'tenantEdit',
        allowedRoles: [roles.admin],
         allowedPlans: [
          plans.free,
          plans.essentials,
          plans.pro,
        ],
      },
      tenantDestroy: {
        id: 'tenantDestroy',
        allowedRoles: [roles.admin],
         allowedPlans: [
          plans.pro
        ],
      },
      planEdit: {
        id: 'planEdit',
        allowedRoles: [roles.admin],
         allowedPlans: [
          plans.free,
          plans.essentials,
          plans.pro,
        ],
      },
      planRead: {
        id: 'planRead',
        allowedRoles: [roles.admin],
         allowedPlans: [
          plans.free,
          plans.essentials,
          plans.pro,
        ],
      },
      userEdit: {
        id: 'userEdit',
        allowedRoles: [roles.admin],
         allowedPlans: [
          plans.free,
          plans.essentials,
          plans.pro,
        ],
      },
      userDestroy: {
        id: 'userDestroy',
        allowedRoles: [roles.admin],
         allowedPlans: [
          plans.pro
        ],
      },
      userCreate: {
        id: 'userCreate',
        allowedRoles: [roles.admin],
         allowedPlans: [
          plans.free,
          plans.essentials,
          plans.pro,
        ],
      },
      userImport: {
        id: 'userImport',
        allowedRoles: [roles.admin],
         allowedPlans: [
          plans.pro
        ],
      },
      userRead: {
        id: 'userRead',
        allowedRoles: [roles.admin],
         allowedPlans: [
          plans.free,
          plans.essentials,
          plans.pro,
        ],
      },
      userAutocomplete: {
        id: 'userAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
         allowedPlans: [
          plans.free,
          plans.essentials,
          plans.pro,
        ],
      },
      auditLogRead: {
        id: 'auditLogRead',
        allowedRoles: [roles.admin],
         allowedPlans: [
          plans.free,
          plans.essentials,
          plans.pro,
        ],
      },
      settingsEdit: {
        id: 'settingsEdit',
        allowedRoles: [roles.admin],
         allowedPlans: [
          plans.free,
          plans.essentials,
          plans.pro,
        ],
        allowedStorage: [
          storage.settingsBackgroundImages,
          storage.settingsLogos,
        ],
      },
      dataRead: {
        id: 'dataRead',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      adminRead: {
        id: 'adminRead',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      researchImport: {
        id: 'researchImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      researchCreate: {
        id: 'researchCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
        allowedStorage: [
          storage.researchResearchDocument,
        ],
      },
      researchEdit: {
        id: 'researchEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
        allowedStorage: [
          storage.researchResearchDocument,
        ],
      },
      researchDestroy: {
        id: 'researchDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
        allowedStorage: [
          storage.researchResearchDocument,
        ],
      },
      researchRead: {
        id: 'researchRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      researchAutocomplete: {
        id: 'researchAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      contactImport: {
        id: 'contactImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      contactCreate: {
        id: 'contactCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
        allowedStorage: [
          storage.researchResearchDocument,
        ],
      },
      contactEdit: {
        id: 'contactEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
        allowedStorage: [
          storage.researchResearchDocument,
        ],
      },
      contactDestroy: {
        id: 'contactDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
        allowedStorage: [
          storage.researchResearchDocument,
        ],
      },
      contactRead: {
        id: 'contactRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      contactAutocomplete: {
        id: 'contactAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      // Events
      eventImport: {
        id: 'eventImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      eventCreate: {
        id: 'eventCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
        allowedStorage: [
          storage.researchResearchDocument,
        ],
      },
      eventEdit: {
        id: 'eventEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
        allowedStorage: [
          storage.researchResearchDocument,
        ],
      },
      eventDestroy: {
        id: 'eventDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      eventRead: {
        id: 'eventRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      eventAutocomplete: {
        id: 'eventAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      feedImport: {
        id: 'feedImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      feedCreate: {
        id: 'feedCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      feedEdit: {
        id: 'feedEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      feedDestroy: {
        id: 'feedDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      feedRead: {
        id: 'feedRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      feedAutocomplete: {
        id: 'feedAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },

      slideImport: {
        id: 'slideImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      slideCreate: {
        id: 'slideCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      slideEdit: {
        id: 'slideEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      slideDestroy: {
        id: 'slideDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      slideRead: {
        id: 'slideRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      slideAutocomplete: {
        id: 'slideAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },

      deckImport: {
        id: 'deckImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      deckCreate: {
        id: 'deckCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      deckEdit: {
        id: 'deckEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      deckDestroy: {
        id: 'deckDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      deckRead: {
        id: 'deckRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      deckAutocomplete: {
        id: 'deckAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },

      topicImport: {
        id: 'topicImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      topicCreate: {
        id: 'topicCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      topicEdit: {
        id: 'topicEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      topicDestroy: {
        id: 'topicDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      topicRead: {
        id: 'topicRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      topicAutocomplete: {
        id: 'topicAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },

      // Help
      helpImport: {
        id: 'helpImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      helpCreate: {
        id: 'helpCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      helpEdit: {
        id: 'helpEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      helpDestroy: {
        id: 'helpDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      helpRead: {
        id: 'helpRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      helpAutocomplete: {
        id: 'helpAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },

      boardImport: {
        id: 'boardImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      boardCreate: {
        id: 'boardCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      boardEdit: {
        id: 'boardEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      boardDestroy: {
        id: 'boardDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      boardRead: {
        id: 'boardRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      boardAutocomplete: {
        id: 'boardAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },

      insightImport: {
        id: 'insightImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      insightCreate: {
        id: 'insightCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
        allowedStorage: [

        ],
      },
      insightEdit: {
        id: 'insightEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
        allowedStorage: [

        ],
      },
      insightDestroy: {
        id: 'insightDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
        allowedStorage: [

        ],
      },
      insightRead: {
        id: 'insightRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      insightAutocomplete: {
        id: 'insightAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },

      definitionImport: {
        id: 'definitionImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      definitionCreate: {
        id: 'definitionCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
        allowedStorage: [

        ],
      },
      definitionEdit: {
        id: 'definitionEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
        allowedStorage: [

        ],
      },
      definitionDestroy: {
        id: 'definitionDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
        allowedStorage: [

        ],
      },
      definitionRead: {
        id: 'definitionRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      definitionAutocomplete: {
        id: 'definitionAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },

      productImport: {
        id: 'productImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      productCreate: {
        id: 'productCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
        allowedStorage: [

        ],
      },
      productEdit: {
        id: 'productEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
        allowedStorage: [

        ],
      },
      productDestroy: {
        id: 'productDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
        allowedStorage: [

        ],
      },
      productRead: {
        id: 'productRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      productAutocomplete: {
        id: 'productAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },

      cohortImport: {
        id: 'cohortImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      cohortCreate: {
        id: 'cohortCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
        allowedStorage: [

        ],
      },
      cohortEdit: {
        id: 'cohortEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
        allowedStorage: [

        ],
      },
      cohortDestroy: {
        id: 'cohortDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
        allowedStorage: [

        ],
      },
      cohortRead: {
        id: 'cohortRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      cohortAutocomplete: {
        id: 'cohortAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },

      bookmarkImport: {
        id: 'bookmarkImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      bookmarkCreate: {
        id: 'bookmarkCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
        allowedStorage: [

        ],
      },
      bookmarkEdit: {
        id: 'bookmarkEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
        allowedStorage: [

        ],
      },
      bookmarkDestroy: {
        id: 'bookmarkDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
        allowedStorage: [

        ],
      },
      bookmarkRead: {
        id: 'bookmarkRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      bookmarkAutocomplete: {
        id: 'bookmarkAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },

      companyImport: {
        id: 'companyImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      companyCreate: {
        id: 'companyCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
        allowedStorage: [

        ],
      },
      companyEdit: {
        id: 'companyEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
        allowedStorage: [

        ],
      },
      companyDestroy: {
        id: 'companyDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
        allowedStorage: [

        ],
      },
      companyRead: {
        id: 'companyRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      companyAutocomplete: {
        id: 'companyAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },

      industryImport: {
        id: 'industryImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      industryCreate: {
        id: 'industryCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
        allowedStorage: [

        ],
      },
      industryEdit: {
        id: 'industryEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
        allowedStorage: [

        ],
      },
      industryDestroy: {
        id: 'industryDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
        allowedStorage: [

        ],
      },
      industryRead: {
        id: 'industryRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      industryAutocomplete: {
        id: 'industryAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },

      countryImport: {
        id: 'countryImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      countryCreate: {
        id: 'countryCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
        allowedStorage: [

        ],
      },
      countryEdit: {
        id: 'countryEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
        allowedStorage: [

        ],
      },
      countryDestroy: {
        id: 'countryDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
        allowedStorage: [

        ],
      },
      countryRead: {
        id: 'countryRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      countryAutocomplete: {
        id: 'countryAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },

      jobTitleImport: {
        id: 'jobTitleImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      jobTitleCreate: {
        id: 'jobTitleCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
        allowedStorage: [

        ],
      },
      jobTitleEdit: {
        id: 'jobTitleEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
        allowedStorage: [

        ],
      },
      jobTitleDestroy: {
        id: 'jobTitleDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
        allowedStorage: [

        ],
      },
      jobTitleRead: {
        id: 'jobTitleRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      jobTitleAutocomplete: {
        id: 'jobTitleAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },

      modelImport: {
        id: 'modelImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      modelCreate: {
        id: 'modelCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
        allowedStorage: [

        ],
      },
      modelEdit: {
        id: 'modelEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
        allowedStorage: [

        ],
      },
      modelDestroy: {
        id: 'modelDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
        allowedStorage: [

        ],
      },
      modelRead: {
        id: 'modelRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      modelAutocomplete: {
        id: 'modelAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },

      marketSegmentImport: {
        id: 'marketSegmentImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      marketSegmentCreate: {
        id: 'marketSegmentCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
        allowedStorage: [

        ],
      },
      marketSegmentEdit: {
        id: 'marketSegmentEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
        allowedStorage: [

        ],
      },
      marketSegmentDestroy: {
        id: 'marketSegmentDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
        allowedStorage: [

        ],
      },
      marketSegmentRead: {
        id: 'marketSegmentRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      marketSegmentAutocomplete: {
        id: 'marketSegmentAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },

      marketSegmentDataImport: {
        id: 'marketSegmentDataImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      marketSegmentDataCreate: {
        id: 'marketSegmentDataCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
        allowedStorage: [

        ],
      },
      marketSegmentDataEdit: {
        id: 'marketSegmentDataEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
        allowedStorage: [

        ],
      },
      marketSegmentDataDestroy: {
        id: 'marketSegmentDataDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
        allowedStorage: [

        ],
      },
      marketSegmentDataRead: {
        id: 'marketSegmentDataRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      marketSegmentDataAutocomplete: {
        id: 'marketSegmentDataAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },

      tagImport: {
        id: 'tagImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      tagCreate: {
        id: 'tagCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
        allowedStorage: [

        ],
      },
      tagEdit: {
        id: 'tagEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
        allowedStorage: [

        ],
      },
      tagDestroy: {
        id: 'tagDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
        allowedStorage: [

        ],
      },
      tagRead: {
        id: 'tagRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      tagAutocomplete: {
        id: 'tagAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },

      referenceRead: {
        id: 'referenceRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },

      customerImport: {
        id: 'customerImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      customerCreate: {
        id: 'customerCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
        allowedStorage: [

        ],
      },
      customerEdit: {
        id: 'customerEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
        allowedStorage: [

        ],
      },
      customerDestroy: {
        id: 'customerDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
        allowedStorage: [

        ],
      },
      customerRead: {
        id: 'customerRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
      customerAutocomplete: {
        id: 'customerAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.essentials, plans.pro],
      },
    };
  }

  static get asArray() {
    return Object.keys(this.values).map((value) => {
      return this.values[value];
    });
  }
}

export default Permissions;
