import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import marketSegmentEnumerators from 'src/modules/marketSegment/marketSegmentEnumerators';

export default [
  {
    name: 'name',
    label: i18n('entities.marketSegment.fields.name'),
    schema: schemas.string(
      i18n('entities.marketSegment.fields.name'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'industries',
    label: i18n('entities.marketSegment.fields.industries'),
    schema: schemas.relationToMany(
      i18n('entities.marketSegment.fields.industries'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'countries',
    label: i18n('entities.marketSegment.fields.countries'),
    schema: schemas.relationToMany(
      i18n('entities.marketSegment.fields.countries'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'employeeCount',
    label: i18n('entities.marketSegment.fields.employeeCount'),
    schema: schemas.stringArray(
      i18n('entities.marketSegment.fields.employeeCount'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'valueModifier',
    label: i18n('entities.marketSegment.fields.valueModifier'),
    schema: schemas.integer(
      i18n('entities.marketSegment.fields.valueModifier'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'sOMModifier',
    label: i18n('entities.marketSegment.fields.sOMModifier'),
    schema: schemas.enumerator(
      i18n('entities.marketSegment.fields.sOMModifier'),
      {
        "required": true,
        "options": marketSegmentEnumerators.sOMModifier
      },
    ),
  },
  {
    name: 'sAMModifier',
    label: i18n('entities.marketSegment.fields.sAMModifier'),
    schema: schemas.enumerator(
      i18n('entities.marketSegment.fields.sAMModifier'),
      {
        "required": true,
        "options": marketSegmentEnumerators.sAMModifier
      },
    ),
  },
];