import authAxios from 'src/modules/shared/axios/authAxios';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';
// import axios from 'axios'

export default class CountryService {
  static async update(id, data) {
    const body = {
      id,
      data,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.put(
      `/tenant/${tenantId}/jobTitle/${id}`,
      body,
    );

    return response.data;
  }

  static async destroyAll(ids) {
    const params = {
      ids,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.delete(
      `/tenant/${tenantId}/jobTitle`,
      {
        params,
      },
    );

    return response.data;
  }

  static async create(data) {
    const body = {
      data,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/jobTitle`,
      body,
    );

    return response.data;
  }

  static async import(values, importHash) {
    const body = {
      data: values,
      importHash,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/jobTitle/import`,
      body,
    );

    return response.data;
  }

  static async find(id) {
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/jobTitle/${id}`,
    );

    return response.data;
  }

  static async list(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/jobTitle`,
      {
        params,
      },
    );

    return response.data;
  }

  static async listAutocomplete(query, limit) {
    const params = {
      query,
      limit,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/jobTitle/autocomplete`,
      {
        params,
      },
    );

    // const response = await axios.get('https://api.peopledatalabs.com/v5/autocomplete',{
    //   headers: {
    //     'X-Api-Key': 'f59ebb5eb8c73369c20b1ff2d7fa2774778fc1539e300e693ceb3192885b3b0d'
    //   },
    //   params: {
    //     field: 'title',
    //     size: limit,
    //     text: query
    //   }
    // }
    // )

    // const responseArray = [];

    // response.data.data.forEach(d => {
    //     const newObject = {
    //       id: d.name.replace(" ","_"),
    //       name: d.name + " (" + d.count + ")"
    //     }
    //     responseArray.push(newObject)
    //   })

    //   return responseArray

    return response.data;

  }
}
