import CompanyService from 'src/modules/company/companyService';
import Errors from 'src/modules/shared/error/errors';

const prefix = 'COMPANY_GETMARKETSEGMENTS';

const companyGetMarketSegmentsActions = {
  GETMARKETSEGMENTS_STARTED: `${prefix}_GETMARKETSEGMENTS_STARTED`,
  GETMARKETSEGMENTS_SUCCESS: `${prefix}_GETMARKETSEGMENTS_SUCCESS`,
  GETMARKETSEGMENTS_ERROR: `${prefix}_GETMARKETSEGMENTS_ERROR`,

  doGetMarketSegments: (id) => async (dispatch) => {
    try {
      dispatch({
        type: companyGetMarketSegmentsActions.GETMARKETSEGMENTS_STARTED,
      });

      const record = await CompanyService.getMarketSegments(id);

      dispatch({
        type: companyGetMarketSegmentsActions.GETMARKETSEGMENTS_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: companyGetMarketSegmentsActions.GETMARKETSEGMENTS_ERROR,
      });

    }
  },
};

export default companyGetMarketSegmentsActions;
