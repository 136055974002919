import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import productEnumerators from 'src/modules/product/productEnumerators';
import moment from 'moment';

export default [
  {
    name: 'productName',
    label: i18n('entities.product.fields.productName'),
    schema: schemas.string(
      i18n('entities.product.fields.productName'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'productDescription',
    label: i18n('entities.product.fields.productDescription'),
    schema: schemas.string(
      i18n('entities.product.fields.productDescription'),
      {
        "min": 5
      },
    ),
  },
  {
    name: 'productStatus',
    label: i18n('entities.product.fields.productStatus'),
    schema: schemas.enumerator(
      i18n('entities.product.fields.productStatus'),
      {
        "required": true,
        "options": productEnumerators.productStatus
      },
    ),
  },
  {
    name: 'productLaunchDate',
    label: i18n('entities.product.fields.productLaunchDate'),
    schema: schemas.date(
      i18n('entities.product.fields.productLaunchDate'),
      {},
    ),
   render: (value) => value && value instanceof Date ? moment(value).format('YYYY-MM-DD') : value,
  },
  {
    name: 'growthRate',
    label: i18n('entities.product.fields.growthRate'),
    schema: schemas.integer(
      i18n('entities.product.fields.growthRate'),
      {
        "required": true,
        "min": 0,
        "max": 1000
      },
    ),
  },
  {
    name: 'currentRevenue',
    label: i18n('entities.product.fields.currentRevenue'),
    schema: schemas.integer(
      i18n('entities.product.fields.currentRevenue'),
      {
        "required": true,
        "min": 0
      },
    ),
  },
  {
    name: 'averageValue1to10',
    label: i18n('entities.product.fields.averageValue1to10'),
    schema: schemas.integer(
      i18n('entities.product.fields.averageValue1to10'),
      {
        "required": true,
        "min": 0
      },
    ),
  },
  {
    name: 'averageValue11to50',
    label: i18n('entities.product.fields.averageValue11to50'),
    schema: schemas.integer(
      i18n('entities.product.fields.averageValue11to50'),
      {
        "required": true,
        "min": 0
      },
    ),
  },
  {
    name: 'averageValue51to200',
    label: i18n('entities.product.fields.averageValue51to200'),
    schema: schemas.integer(
      i18n('entities.product.fields.averageValue51to200'),
      {
        "required": true,
        "min": 0
      },
    ),
  },
  {
    name: 'averageValue201to500',
    label: i18n('entities.product.fields.averageValue201to500'),
    schema: schemas.integer(
      i18n('entities.product.fields.averageValue201to500'),
      {
        "required": true,
        "min": 0
      },
    ),
  },
  {
    name: 'averageValue501to1000',
    label: i18n('entities.product.fields.averageValue501to1000'),
    schema: schemas.integer(
      i18n('entities.product.fields.averageValue501to1000'),
      {
        "required": true,
        "min": 0
      },
    ),
  },
  {
    name: 'averageValue1001to5000',
    label: i18n('entities.product.fields.averageValue1001to5000'),
    schema: schemas.integer(
      i18n('entities.product.fields.averageValue1001to5000'),
      {
        "required": true,
        "min": 0
      },
    ),
  },
  {
    name: 'averageValue5001to10000',
    label: i18n('entities.product.fields.averageValue5001to10000'),
    schema: schemas.integer(
      i18n('entities.product.fields.averageValue5001to10000'),
      {
        "required": true,
        "min": 0
      },
    ),
  },
  {
    name: 'averageValue10001+',
    label: i18n('entities.product.fields.averageValue10000+'),
    schema: schemas.integer(
      i18n('entities.product.fields.averageValue10000+'),
      {
        "required": true,
        "min": 0
      },
    ),
  },
  {
    name: 'marketSegments',
    label: i18n('entities.product.fields.marketSegments'),
    schema: schemas.relationToMany(
      i18n('entities.product.fields.marketSegments'),
      {},
    ),
  },
];