import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import researchEnumerators from 'src/modules/research/researchEnumerators';

export default [
  {
    name: 'researchTitle',
    label: i18n('entities.research.fields.researchTitle'),
    schema: schemas.string(
      i18n('entities.research.fields.researchTitle'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'researchSummary',
    label: i18n('entities.research.fields.researchSummary'),
    schema: schemas.string(
      i18n('entities.research.fields.researchSummary'),
      {
        "min": 5
      },
    ),
  },
  {
    name: 'researchDocument',
    label: i18n('entities.research.fields.researchDocument'),
    schema: schemas.files(
      i18n('entities.research.fields.researchDocument'),
      {},
    ),
  },
  {
    name: 'researchType',
    label: i18n('entities.research.fields.researchType'),
    schema: schemas.enumerator(
      i18n('entities.research.fields.researchType'),
      {
        "required": true,
        "options": researchEnumerators.researchType
      },
    ),
  },
  {
    name: 'annotationURL',
    label: i18n('entities.research.fields.annotationURL'),
    schema: schemas.string(
      i18n('entities.research.fields.annotationURL'),
      {
        "required": false
      },
    ),
  },
];