import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'name',
    label: i18n('entities.model.fields.name'),
    schema: schemas.string(
      i18n('entities.model.fields.name'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'description',
    label: i18n('entities.model.fields.description'),
    schema: schemas.string(
      i18n('entities.model.fields.description'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'products',
    label: i18n('entities.model.fields.products'),
    schema: schemas.relationToMany(
      i18n('entities.model.fields.products'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'marketSegments',
    label: i18n('entities.model.fields.marketSegments'),
    schema: schemas.relationToMany(
      i18n('entities.model.fields.marketSegments'),
      {
        "required": true
      },
    ),
  },
];