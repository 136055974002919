import list from 'src/modules/marketSegment/list/marketSegmentListReducers';
import form from 'src/modules/marketSegment/form/marketSegmentFormReducers';
import view from 'src/modules/marketSegment/view/marketSegmentViewReducers';
import destroy from 'src/modules/marketSegment/destroy/marketSegmentDestroyReducers';
import importerReducer from 'src/modules/marketSegment/importer/marketSegmentImporterReducers';
import recalculate from 'src/modules/marketSegment/recalculate/marketSegmentRecalculateReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  recalculate,
  importer: importerReducer,
});
