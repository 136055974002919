import listActions from 'src/modules/bookmark/list/bookmarkListActions';
import BookmarkService from 'src/modules/bookmark/bookmarkService';
import Errors from 'src/modules/shared/error/errors';
import { i18n } from 'src/i18n';
import Message from 'src/view/shared/message';

const prefix = 'BOOKMARK_SNAPSHOT';

const bookmarkSnapshotActions = {
  SNAPSHOT_STARTED: `${prefix}_SNAPSHOT_STARTED`,
  SNAPSHOT_SUCCESS: `${prefix}_SNAPSHOT_SUCCESS`,
  SNAPSHOT_ERROR: `${prefix}_SNAPSHOT_ERROR`,


  doSnapshot: (id,setForceUpdate) => async (dispatch) => {
    try {
      dispatch({
        type: bookmarkSnapshotActions.SNAPSHOT_STARTED,
      });

      await BookmarkService.snapshot([id]);

      setForceUpdate(1);
      
      dispatch({
        type: bookmarkSnapshotActions.SNAPSHOT_SUCCESS,
      });
      

      Message.success(
        i18n('entities.bookmark.snapshot.success'),
      );
      

    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: bookmarkSnapshotActions.SNAPSHOT_ERROR,
      });
    }
  },
};

export default bookmarkSnapshotActions;
