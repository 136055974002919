import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.marketSegmentData.fields.id'),
  },
  {
    name: 'industry',
    label: i18n('entities.marketSegmentData.fields.industry'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'country',
    label: i18n('entities.marketSegment.fields.country'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'employeeCount',
    label: i18n('entities.marketSegment.fields.employeeCount'),
    render: exporterRenders.stringArray(),
  },
  {
    name: 'count',
    label: i18n('entities.marketSegment.fields.count'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.marketSegment.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.marketSegment.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
