import ResearchService from 'src/modules/research/researchService';
import Errors from 'src/modules/shared/error/errors';
import Message from 'src/view/shared/message';
import { getHistory } from 'src/modules/store';
import { i18n } from 'src/i18n';

const prefix = 'RESEARCH_FORM';

const researchFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  doInit: (id) => async (dispatch) => {
    try {
      dispatch({
        type: researchFormActions.INIT_STARTED,
      });

      let record = {};

      const isEdit = Boolean(id);

      if (isEdit) {
        record = await ResearchService.find(id);
      }

      dispatch({
        type: researchFormActions.INIT_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: researchFormActions.INIT_ERROR,
      });

      getHistory().push('/research');
    }
  },

  doCreate: (values) => async (dispatch) => {
    try {
      dispatch({
        type: researchFormActions.CREATE_STARTED,
      });

      const response = await ResearchService.create(values);

      dispatch({
        type: researchFormActions.CREATE_SUCCESS,
      });

      Message.success(
        i18n('entities.research.create.success'),
      );

      getHistory().push('/research/' + response.id);
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: researchFormActions.CREATE_ERROR,
      });
    }
  },

  doUpdate: (id, values) => async (dispatch, getState) => {
    try {
      dispatch({
        type: researchFormActions.UPDATE_STARTED,
      });

      await ResearchService.update(id, values);

      dispatch({
        type: researchFormActions.UPDATE_SUCCESS,
      });

      Message.success(
        i18n('entities.research.update.success'),
      );

      getHistory().push('/research');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: researchFormActions.UPDATE_ERROR,
      });
    }
  },
};

export default researchFormActions;
