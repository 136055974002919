import list from 'src/modules/insight/list/insightListReducers';
import form from 'src/modules/insight/form/insightFormReducers';
import view from 'src/modules/insight/view/insightViewReducers';
import destroy from 'src/modules/insight/destroy/insightDestroyReducers';
import importerReducer from 'src/modules/insight/importer/insightImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
