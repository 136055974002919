import list from 'src/modules/help/list/helpListReducers';
import form from 'src/modules/help/form/helpFormReducers';
import view from 'src/modules/help/view/helpViewReducers';
import destroy from 'src/modules/help/destroy/helpDestroyReducers';
import importerReducer from 'src/modules/help/importer/helpImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
