import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'label',
    label: i18n('entities.definition.fields.label'),
    schema: schemas.string(
      i18n('entities.definition.fields.label'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'url',
    label: i18n('entities.definition.fields.url'),
    schema: schemas.string(
      i18n('entities.definition.fields.url'),
      {
        "required": true
      },
    ),
  },
];