import list from 'src/modules/company/list/companyListReducers';
import form from 'src/modules/company/form/companyFormReducers';
import view from 'src/modules/company/view/companyViewReducers';
import destroy from 'src/modules/company/destroy/companyDestroyReducers';
import getWallet from 'src/modules/company/getWallet/companyGetWalletReducers'
import getSimilarWallet from 'src/modules/company/getSimilarWallet/companyGetSimilarWalletReducers'
import getMarketSegments from 'src/modules/company/getMarketSegments/companyGetMarketSegmentsReducers'
import importerReducer from 'src/modules/company/importer/companyImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  getWallet,
  getSimilarWallet,
  getMarketSegments,
  importer: importerReducer,
});
