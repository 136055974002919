import { connectRouter } from 'connected-react-router';
import layout from 'src/modules/layout/layoutReducers';
import auth from 'src/modules/auth/authReducers';
import tenant from 'src/modules/tenant/tenantReducers';
import plan from 'src/modules/plan/planReducers';
import user from 'src/modules/user/userReducers';
import auditLog from 'src/modules/auditLog/auditLogReducers';
import settings from 'src/modules/settings/settingsReducers';
import definition from 'src/modules/definition/definitionReducers';
import research from 'src/modules/research/researchReducers';
import insight from 'src/modules/insight/insightReducers';
import product from 'src/modules/product/productReducers';
import company from 'src/modules/company/companyReducers';
import industry from 'src/modules/industry/industryReducers';
import country from 'src/modules/country/countryReducers';
import model from 'src/modules/model/modelReducers';
import marketSegment from 'src/modules/marketSegment/marketSegmentReducers';
import marketSegmentData from 'src/modules/marketSegmentData/marketSegmentDataReducers';
import tag from 'src/modules/tag/tagReducers';
import bookmark from 'src/modules/bookmark/bookmarkReducers';
import cohort from 'src/modules/cohort/cohortReducers';
import topic from 'src/modules/topic/topicReducers';
import board from 'src/modules/board/boardReducers';
import customer from 'src/modules/customer/customerReducers';
import contact from 'src/modules/contact/contactReducers';
import jobTitle from 'src/modules/jobTitle/jobTitleReducers';
import slide from 'src/modules/slide/slideReducers';
import deck from 'src/modules/deck/deckReducers';
import feed from 'src/modules/feed/feedReducers';
import event from 'src/modules/event/eventReducers';
import help from 'src/modules/help/helpReducers';

import { combineReducers } from 'redux';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    layout,
    auth,
    tenant,
    plan,
    user,
    auditLog,
    settings,
    definition,
    research,
    insight,
    product,
    company,
    industry,
    country,
    model,
    marketSegment,
    marketSegmentData,
    tag,
    bookmark,
    cohort,
    topic,
    board,
    customer,
    contact,
    jobTitle,
    slide,
    deck,
    feed,
    event,
    help
  });
