import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import customerEnumerators from 'src/modules/customer/customerEnumerators';

export default [
  {
    name: 'name',
    label: i18n('entities.customer.fields.name'),
    schema: schemas.string(
      i18n('entities.customer.fields.name'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'website',
    label: i18n('entities.customer.fields.website'),
    schema: schemas.string(
      i18n('entities.customer.fields.website'),
      {},
    ),
  },
  {
    name: 'employeeCount',
    label: i18n('entities.customer.fields.employeeCount'),
    schema: schemas.enumerator(
      i18n('entities.customer.fields.employeeCount'),
      {
        "options": customerEnumerators.employeeCount
      },
    ),
  },
  {
    name: 'industry',
    label: i18n('entities.customer.fields.industry'),
    schema: schemas.relationToOne(
      i18n('entities.customer.fields.industry'),
      {},
    ),
  },
  {
    name: 'country',
    label: i18n('entities.customer.fields.country'),
    schema: schemas.relationToOne(
      i18n('entities.customer.fields.country'),
      {},
    ),
  },
];