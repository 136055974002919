import CompanyService from 'src/modules/company/companyService';
import Errors from 'src/modules/shared/error/errors';

const prefix = 'COMPANY_GETWALLET';

const companyGetWalletActions = {
  GETWALLET_STARTED: `${prefix}_GETWALLET_STARTED`,
  GETWALLET_SUCCESS: `${prefix}_GETWALLET_SUCCESS`,
  GETWALLET_ERROR: `${prefix}_GETWALLET_ERROR`,

  doGetWallet: (id) => async (dispatch) => {
    try {
      dispatch({
        type: companyGetWalletActions.GETWALLET_STARTED,
      });

      const record = await CompanyService.getWallet(id);

      dispatch({
        type: companyGetWalletActions.GETWALLET_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: companyGetWalletActions.GETWALLET_ERROR,
      });

    }
  },
};

export default companyGetWalletActions;
