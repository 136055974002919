// Place the URL here with the /api suffix.
// Ex.:`https://domain.com/api`;
const backendUrl = `https://dare-bc6jno3nda-ue.a.run.app/api`;

// SwaggerUI Documentation URL
// Leave black if documentation should be hidden
const apiDocumentationUrl = `https://dare-bc6jno3nda-ue.a.run.app/documentation`;

/**
 * Frontend URL.
 */
const frontendUrl = {
  // host: 'https://production-327813.web.app',
  host: 'https://productdeck.app',
  protocol: 'https',
};

/**
 * Tenant Mode
 * multi: Allow new users to create new tenants.
 * multi-with-subdomain: Same as multi, but enable access to the tenant via subdomain.
 * single: One tenant, the first user to register will be the admin.
 */
const tenantMode = 'multi';
const masterTenantId = '61574834adce64319fa07107'

/**
 * Plan payments configuration.
 */
const isPlanEnabled = true;
const stripePublishableKey = 'pk_test_51KBp5WCF4BBWdOkLnncqVKkjICVtBz2hmpVtH9Om5eTn7RoD25R3adPpv9R3zyKEuvcjV1pJDEH7NQZFLT0QOCGj00YFkJ11uL';

export default {
  frontendUrl,
  backendUrl,
  apiDocumentationUrl,
  tenantMode,
  masterTenantId,
  isPlanEnabled,
  stripePublishableKey
};
