import list from 'src/modules/industry/list/industryListReducers';
import form from 'src/modules/industry/form/industryFormReducers';
import view from 'src/modules/industry/view/industryViewReducers';
import destroy from 'src/modules/industry/destroy/industryDestroyReducers';
import importerReducer from 'src/modules/industry/importer/industryImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
