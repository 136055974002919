import list from 'src/modules/definition/list/definitionListReducers';
import form from 'src/modules/definition/form/definitionFormReducers';
import view from 'src/modules/definition/view/definitionViewReducers';
import destroy from 'src/modules/definition/destroy/definitionDestroyReducers';
import importerReducer from 'src/modules/definition/importer/definitionImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
