import CompanyService from 'src/modules/company/companyService';
import Errors from 'src/modules/shared/error/errors';

const prefix = 'COMPANY_GETSIMILARWALLET';

const companyGetSimilarWalletActions = {
  GETSIMILARWALLET_STARTED: `${prefix}_GETSIMILARWALLET_STARTED`,
  GETSIMILARWALLET_SUCCESS: `${prefix}_GETSIMILARWALLET_SUCCESS`,
  GETSIMILARWALLET_ERROR: `${prefix}_GETSIMILARWALLET_ERROR`,

  doGetSimilarWallet: (id) => async (dispatch) => {
    try {
      dispatch({
        type: companyGetSimilarWalletActions.GETSIMILARWALLET_STARTED,
      });

      const record = await CompanyService.getSimilarWallet(id);

      dispatch({
        type: companyGetSimilarWalletActions.GETSIMILARWALLET_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: companyGetSimilarWalletActions.GETSIMILARWALLET_ERROR,
      });

    }
  },
};

export default companyGetSimilarWalletActions;
