import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'help',
    label: i18n('entities.help.fields.help'),
    schema: schemas.string(
      i18n('entities.help.fields.help'),
      {
        "min": 5
      },
    ),
  },
  {
    name: 'source',
    label: i18n('entities.help.fields.source'),
    schema: schemas.relationToOne(
      i18n('entities.help.fields.source'),
      {},
    ),
  },
  {
    name: 'collectedBy',
    label: i18n('entities.help.fields.collectedBy'),
    schema: schemas.relationToOne(
      i18n('entities.help.fields.collectedBy'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'page',
    label: i18n('entities.help.fields.page'),
    schema: schemas.integer(
      i18n('entities.help.fields.page'),
      {},
    ),
  },
];