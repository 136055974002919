import list from 'src/modules/board/list/boardListReducers';
import form from 'src/modules/board/form/boardFormReducers';
import view from 'src/modules/board/view/boardViewReducers';
import destroy from 'src/modules/board/destroy/boardDestroyReducers';
import importerReducer from 'src/modules/board/importer/boardImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
