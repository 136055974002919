import list from 'src/modules/feed/list/feedListReducers';
import form from 'src/modules/feed/form/feedFormReducers';
import view from 'src/modules/feed/view/feedViewReducers';
import destroy from 'src/modules/feed/destroy/feedDestroyReducers';
import importerReducer from 'src/modules/feed/importer/feedImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
