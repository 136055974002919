import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.research.fields.id'),
  },
  {
    name: 'researchTitle',
    label: i18n('entities.research.fields.researchTitle'),
  },
  {
    name: 'researchSummary',
    label: i18n('entities.research.fields.researchSummary'),
  },
  {
    name: 'researchDocument',
    label: i18n('entities.research.fields.researchDocument'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'researchType',
    label: i18n('entities.research.fields.researchType'),
  },
  {
    name: 'annotationURL',
    label: i18n('entities.research.fields.annotationURL'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.research.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.research.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
