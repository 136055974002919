import listActions from 'src/modules/marketSegment/list/marketSegmentListActions';
import MarketSegmentService from 'src/modules/marketSegment/marketSegmentService';
import Errors from 'src/modules/shared/error/errors';
import { i18n } from 'src/i18n';
import Message from 'src/view/shared/message';

const prefix = 'MARKETSEGMENT_RECALCULATE';

const marketSegmentRecalculateActions = {
  RECALCULATE_STARTED: `${prefix}_RECALCULATE_STARTED`,
  RECALCULATE_SUCCESS: `${prefix}_RECALCULATE_SUCCESS`,
  RECALCULATE_ERROR: `${prefix}_RECALCULATE_ERROR`,


  doRecalculate: (id,forceUpdate,setForceUpdate) => async (dispatch) => {
    try {
      dispatch({
        type: marketSegmentRecalculateActions.RECALCULATE_STARTED,
      });

      await MarketSegmentService.recalculate([id]);

      dispatch({
        type: marketSegmentRecalculateActions.RECALCULATE_SUCCESS,
      });

      Message.success(
        i18n('entities.marketSegment.recalculate.success'),
      );
      
      // Update charts and analytics following recalculation
      setForceUpdate(forceUpdate+1);


    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: marketSegmentRecalculateActions.RECALCULATE_ERROR,
      });
    }
  },
};

export default marketSegmentRecalculateActions;
