import listActions from 'src/modules/bookmark/list/bookmarkListActions';
import BookmarkService from 'src/modules/bookmark/bookmarkService';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';

const prefix = 'BOOKMARK_CREATERESEARCH';

const bookmarkCreateResearchActions = {
  CREATERESEARCH_STARTED: `${prefix}_CREATERESEARCH_STARTED`,
  CREATERESEARCH_SUCCESS: `${prefix}_CREATERESEARCH_SUCCESS`,
  CREATERESEARCH_ERROR: `${prefix}_CREATERESEARCH_ERROR`,


  doCreateResearch: (id) => async (dispatch) => {
    try {
      dispatch({
        type: bookmarkCreateResearchActions.CREATERESEARCH_STARTED,
      });

      const researchID = await BookmarkService.createResearch([id]);

      dispatch({
        type: bookmarkCreateResearchActions.CREATERESEARCH_SUCCESS,
      });

      getHistory().push('/research/' + researchID)

    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: bookmarkCreateResearchActions.CREATERESEARCH_ERROR,
      });
    }
  },
};

export default bookmarkCreateResearchActions;
