import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/slide/importer/slideImporterSelectors';
import SlideService from 'src/modules/slide/slideService';
import fields from 'src/modules/slide/importer/slideImporterFields';
import { i18n } from 'src/i18n';

const slideImporterActions = importerActions(
  'SLIDE_IMPORTER',
  selectors,
  SlideService.import,
  fields,
  i18n('entities.slide.importer.fileName'),
);

export default slideImporterActions;