import list from 'src/modules/bookmark/list/bookmarkListReducers';
import form from 'src/modules/bookmark/form/bookmarkFormReducers';
import view from 'src/modules/bookmark/view/bookmarkViewReducers';
import destroy from 'src/modules/bookmark/destroy/bookmarkDestroyReducers';
import snapshot from 'src/modules/bookmark/snapshot/bookmarkSnapshotReducers';
import createResearch from 'src/modules/bookmark/createResearch/bookmarkCreateResearchReducers';
import importerReducer from 'src/modules/bookmark/importer/bookmarkImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  snapshot,
  createResearch,
  importer: importerReducer,
});
