import list from 'src/modules/research/list/researchListReducers';
import form from 'src/modules/research/form/researchFormReducers';
import view from 'src/modules/research/view/researchViewReducers';
import destroy from 'src/modules/research/destroy/researchDestroyReducers';
import importerReducer from 'src/modules/research/importer/researchImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
