import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.cohort.fields.id'),
  },
  {
    name: 'label',
    label: i18n('entities.cohort.fields.label'),
  },
  {
    name: 'url',
    label: i18n('entities.cohort.fields.url'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.tag.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.tag.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
