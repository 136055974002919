import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/help/importer/helpImporterSelectors';
import HelpService from 'src/modules/help/helpService';
import fields from 'src/modules/help/importer/helpImporterFields';
import { i18n } from 'src/i18n';

const helpImporterActions = importerActions(
  'TOPIC_IMPORTER',
  selectors,
  HelpService.import,
  fields,
  i18n('entities.help.importer.fileName'),
);

export default helpImporterActions;