const eventEnumerators = {
  eventType: [
    "Release",
    "Milestone",
    "Conference",
    "Webinar",
    "Launch",
    "Client Meeting",
    "Win",
    "Loss",
    "Pitch",
    "Outage",
    "Other"
  ],
};

export default eventEnumerators;
