import list from 'src/modules/slide/list/slideListReducers';
import form from 'src/modules/slide/form/slideFormReducers';
import view from 'src/modules/slide/view/slideViewReducers';
import destroy from 'src/modules/slide/destroy/slideDestroyReducers';
import importerReducer from 'src/modules/slide/importer/slideImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
