const researchEnumerators = {
  researchType: [
    'Client_Interview',
    'Industry_Report',
    'Research_Document',
    'Competitor_Analysis',
    'Market_Research',
    'Meeting_Notes',
    'Other',
    'Product_Feedback',
    'RFP',
    'RFI',
    'Win/Loss_Analysis',
    'Workshop_Session'
  ],
};

export default researchEnumerators;
