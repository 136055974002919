import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'industry',
    label: i18n('entities.marketSegmentData.fields.industry'),
    schema: schemas.relationToOne(
      i18n('entities.marketSegmentData.fields.industry'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'country',
    label: i18n('entities.marketSegmentData.fields.country'),
    schema: schemas.relationToOne(
      i18n('entities.marketSegmentData.fields.country'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'employeeCount',
    label: i18n('entities.marketSegmentData.fields.employeeCount'),
    schema: schemas.stringArray(
      i18n('entities.marketSegmentData.fields.employeeCount'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'count',
    label: i18n('entities.marketSegmentData.fields.count'),
    schema: schemas.integer(
      i18n('entities.marketSegmentData.fields.count'),
      {
        "required": true
      },
    ),
  }
];