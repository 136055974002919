import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/marketSegment/importer/marketSegmentImporterSelectors';
import MarketSegmentService from 'src/modules/marketSegment/marketSegmentService';
import fields from 'src/modules/marketSegment/importer/marketSegmentImporterFields';
import { i18n } from 'src/i18n';

const marketSegmentImporterActions = importerActions(
  'MARKETSEGMENT_IMPORTER',
  selectors,
  MarketSegmentService.import,
  fields,
  i18n('entities.marketSegment.importer.fileName'),
);

export default marketSegmentImporterActions;