const marketSegmentEnumerators = {
  employeeCount: [
    '1-10',
    '11-50',
    '51-200',
    '201-500',
    '501-1000',
    '1001-5000',
    '5001-10000',
    '10001+',
  ],
  sOMModifier: [
    'Very_Easy',
    'Easy',
    'Medium',
    'Difficult',
    'Very_Difficult',
  ],
  sAMModifier: [
    'Very_Easy',
    'Easy',
    'Medium',
    'Difficult',
    'Very_Difficult',
  ],
};

export default marketSegmentEnumerators;
