import ModelService from 'src/modules/model/modelService';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';

const prefix = 'MODEL_VIEW';

const modelViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: modelViewActions.FIND_STARTED,
      });

      const record = await ModelService.find(id);

      dispatch({
        type: modelViewActions.FIND_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: modelViewActions.FIND_ERROR,
      });

      getHistory().push('/model');
    }
  },
};

export default modelViewActions;
