import list from 'src/modules/model/list/modelListReducers';
import form from 'src/modules/model/form/modelFormReducers';
import view from 'src/modules/model/view/modelViewReducers';
import destroy from 'src/modules/model/destroy/modelDestroyReducers';
import recalculate from 'src/modules/model/recalculate/modelRecalculateReducers';
import importerReducer from 'src/modules/model/importer/modelImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  recalculate,
  importer: importerReducer,
});
