import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/cohort/importer/cohortImporterSelectors';
import CohortService from 'src/modules/cohort/cohortService';
import fields from 'src/modules/cohort/importer/cohortImporterFields';
import { i18n } from 'src/i18n';

const cohortImporterActions = importerActions(
  'BOOKMARK_IMPORTER',
  selectors,
  CohortService.import,
  fields,
  i18n('entities.cohort.importer.fileName'),
);

export default cohortImporterActions;