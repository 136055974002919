import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.help.fields.id'),
  },
  {
    name: 'help',
    label: i18n('entities.help.fields.help'),
  },
  {
    name: 'source',
    label: i18n('entities.help.fields.source'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'collectedBy',
    label: i18n('entities.help.fields.collectedBy'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'page',
    label: i18n('entities.help.fields.page'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.help.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.help.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
